
export class Modal {

    constructor() {
        this.$modalContainer = null;
    }

    load(url, callback) {

      if (this.$modalContainer === null) {
        this.$modalContainer = $('<div></div>');
        $('body').append(this.$modalContainer);
      }

      this.$modalContainer.load(url, _ => callback(this.$modalContainer.children()));
    }
}

const modal = new Modal();

export { modal };
