export class PopoverHandler {

  constructor($container) {
    this.$container = $container || $(document);
    this.init();
  }

  init() {
    this.$container.find("[data-toggle=popover]").each((index, element) => {
      const $element = $(element);
      $element.popover({
        'placement': $element.data("popover-placement") || "top",
        'trigger': $element.data("popover-trigger") || "hover",
        'html':  $element.data("popover-html") !== false,
        'container': $element.data("popover-container") || "body",
      });
    });
  }
}
